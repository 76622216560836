import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Datastore } from './datastore';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { Md5 } from 'ts-md5';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private authUrl = '/api/v1/users/auth';

    private defaultIncludes = '';

    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(
        private readonly http: HttpClient,
        private readonly datastore: Datastore,
        private readonly router: Router
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(null);
        this.currentUser = this.currentUserSubject.asObservable();

        if (this.hasToken()) {
            this.forceFetchCurrentUser().subscribe();
        }
    }

    /**
     * Set current user
     */
    protected setCurrentUser(user: User = null): void {
        this.currentUserSubject.next(user);
    }

    /**
     * Empty current user and get the new user data.
     */
    public forceFetchCurrentUser(): Observable<boolean> {
        this.currentUserSubject.next(null);
        return this.isLoggedIn();
    }

    public getToken(): string | null {
        return window.sessionStorage.getItem('token');
    }

    public hasToken(): boolean {
        return window.sessionStorage.getItem('token') !== null;
    }

    public setToken(token: string|null): void {
        window.sessionStorage.setItem('token', token);
        this.forceFetchCurrentUser().subscribe();
    }

    /**
     * Method to check and/or retrieve the user if it is logged in.
     */
    public isLoggedIn(): Observable<boolean> {
        if (this.currentUserSubject.value instanceof User) {
            return of(true);
        }

        return this.datastore.findRecord(
            User,
            null,
            { include: this.defaultIncludes },
            null,
            `${this.authUrl}/current`
        ).pipe(
            map((user: User) => {
                this.setCurrentUser(user);
                return true;
            }),
            catchError((error) => {
                this.currentUserSubject.next(null);
                return of(false);
            })
        );
    }

    /**
     * Logout the current User.
     */
    public logout(): Observable<string | boolean | object> {
        const headers = new HttpHeaders().set('Content-Type', 'application/vnd.api+json');
        return this.http.get(`${this.authUrl}/logout`, { headers })
            .pipe(
                tap(() => {
                    this.setCurrentUser(null);
                    this.setToken(null);
                    this.navigateToLogin();
                }),
                catchError(() => {
                    this.router.navigateByUrl('/');
                    return of('loggingout');
                })
            );
    }

    public navigateToLogin(): void {
        this.router.navigate(['/'], { queryParams: { redirect: this.router.url } });
    }

    /**
     * Get current user from subject.
     */
    public getCurrentUser(): User | null {
        return this.currentUserSubject.getValue();
    }

    /**
     * Get current user's profile picture.
     */
    public getCurrentUserAvatar(): string {
        return 'https://www.gravatar.com/avatar/' + Md5.hashStr(this.getCurrentUser()?.email ?? '') + '?d=retro';
    }
}
